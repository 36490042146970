.test-case-container{
    width: 70%;
    margin-top: 10px;
}
.test-sub-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

/* .table-wrapper {
    max-height: 65vh;
    overflow-y: auto;
  }
  
  .table-wrapper table {
    width: 100%;
    border-collapse: collapse;
  } */
  
  .attachmentContainer {
    display:'flex';
    /* flex-direction: row; */
    overflow-x: 'auto';
  }

  /* .table-wrapper th,
  .table-wrapper td {
    padding: 8px;
    text-align: left;
    border: 1px solid #ddd;
  }
  
  .table-wrapper th {
    background-color: #f2f2f2;
  } */


  /* Media Queries */
@media (max-width: 1200px) {
    .table-wrapper {
      max-height: 66rem; /* Medium screens */
    }
  }
  
  @media (max-width: 992px) {
    .table-wrapper {
      max-height: 25rem; /* Small screens */
    }
  }
  
  @media (max-width: 768px) {
    .table-wrapper {
      max-height: 22rem; /* Extra small screens */
    }
  }
  
  @media (max-width: 576px) {
    .table-wrapper {
      max-height: 150px; /* Mobile devices */
    }
  }