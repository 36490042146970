ul {
  padding: 0 !important;
}
ul li {
  padding: 4px 5px 2px 5px;
}
ul li label {
  font-size: 12px;
  font-weight: 400;
  padding-left: 12px;
}

ul li input {
  margin: 5px -8px !important;
}
.pagination li{
  padding: 0px;
}
.dropdown-toggle
 {
  margin: 0 !important;  
  width: 100% !important;
  background: #fff !important;
  color: #333 !important;
    border: 1px solid var(--grey-200) !important;
        text-align: left !important;
          display: flex;
          justify-content: space-between;
          align-items: center;font-size: 12px !important;
        
    
}
.dropdown-toggle:hover{
    background: #fff !important;
        color: #333 !important;
    }
