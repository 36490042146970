@tailwind base;
@tailwind components;
@tailwind utilities;

*,
::after,
::before {
  box-sizing: border-box;
}

:root {
  /* colors */

  --primary-500-active: #307daf;
  --blue: #307daf;
  --primary-500: #14689f;

  /* grey */
  --grey-50: #f0f4f8;
  --grey-100: #d9e2ec;
  --grey-200: #bcccdc;
  --grey-300: #9fb3c8;
  --grey-400: #829ab1;
  --grey-500: #627d98;
  --grey-600: #486581;
  --grey-700: #334e68;
  --grey-800: #243b53;
  --grey-900: #102a43;
  /* rest of the colors */
  --black: #222;
  --white: #fff;
  --red-light: #f8d7da;
  --red-dark: #842029;
  --green-light: #d1e7dd;
  --green-dark: #0f5132;
  --label-color: #374151;
  --label-bg: #edf5fd;
  --font-11: 11px;
  --font-12: 12px;
  --font-13: 13px;
  --font-14: 14px;
  --font-15: 15px;
  --font-16: 16px;
  --font-18: 18px;
  --font-20: 20px;
  --font-25: 25px;
  /* fonts  */

  --small-text: 0.875rem;
  --extra-small-text: 0.7em;
  /* rest of the vars */
  --backgroundColor: var(--grey-50);

  --textColor: var(--grey-900);
  --borderRadius: 0.25rem;
  --letterSpacing: 1px;
  --transition: 0.3s ease-in-out all;
  --max-width: 1120px;
  --fixed-width: 500px;
  --fluid-width: 90vw;
  --breakpoint-lg: 992px;
  --nav-height: 4rem;
  /* box shadow*/
  --shadow-1: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  --shadow-2: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-3: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --shadow-4: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  --shadow-5: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px,
    rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;

  --linear-gradiant: linear-gradient(348deg,
      rgba(228, 233, 223, 1) 0%,
      rgb(79 162 190) 50%,
      rgba(240, 236, 232, 1) 100%);
}

body {
  background: var(--backgroundColor);
  /* //font-family: "Poppins", sans-serif; */
  font-weight: 400;
  line-height: 1.75;
  color: var(--textColor);
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  margin-bottom: 1.38rem;
  font-weight: 400;
  line-height: 1.3;
  text-transform: capitalize;
  color: var(--label-color) !important;
}

.toggle-btn:hover {
  color: var(--primary-500) !important;
  background: #f7f9fc !important;
  ;
  box-shadow: #f7f9fc 0px 8px 24px !important;
}

.dropdown-btn:hover,
.addtask:hover,
.dropdown-item:hover,
.dropdown-item:focus {
  color: var(--label-color) !important;
  background-color: var(--grey-50) !important;
}

.mb-6 {
  margin-bottom: 25px !important;
}

select option {
  font-size: var(--font-12) !important;
}

select option:hover {
  background-color: var(--grey-50) !important;
}

.form {
  width: 90vw;
  max-width: var(--fixed-width);
  background: var(--white);
  border-radius: var(--borderRadius);
  box-shadow: var(--shadow-2);
  padding: 2rem 2.5rem;
  margin: 3rem auto;
  transition: var(--transition);
}

p {
  color: var(--label-color) !important;
}

.form:hover {
  box-shadow: var(--shadow-4);
}

.form-label {
  display: block;
  /* //   font-size: var(--smallText); */
  margin-bottom: 0.2rem !important;
  text-transform: capitalize;
  color: var(--label-color);
}

.form-input,
.form-textarea,
.form-select {
  width: 100%;
  padding: 10px 10px 10px 12px !important;
  border-radius: var(--borderRadius);
  background: var(--white);
  border: 1px solid var(--grey-200);
}

 
.form-select {
  text-transform: capitalize;
}

button,
.btn {
  font-weight: 500 !important;
  cursor: pointer;
  font-size: var(--font-14) !important;
  outline:none !important;
}

.nav-center button .toggle-btn svg:hover {
  background: red !important;

  color: var(--primary-500) !important;
}

.modal-header {
  padding: 0px !important;
  border-bottom: 0px solid !important;
}



.modal-header .btn-close:hover,
.modal-header button .btn-close:hover {
  color: inherit !important;
  background-color: rgb(255, 249, 249) !important;
  outline: none !important;
  box-shadow: 0 0 5px 0 #efefef79;
}

button:hover,
.btn:hover {
  color: #fff !important;
  background-color: var(--primary-500) !important;
  outline: none !important;
  box-shadow: rgba(42, 89, 152, 0.15) 0px 0px 50px;
}

.ql-formats button {
  padding: 3px 5px !important;
  width: 28px;
}

.form-row {
  margin-bottom: 8px !important;
}

.form-textarea {
  height: 7rem;
}

::placeholder {
  font-family: inherit;
  color: var(--grey-400);
}

.form-alert {
  color: var(--red-dark);
  /* //  letter-spacing: var(--letterSpacing); */
  text-transform: capitalize;
}

/* buttons */

.btn {
  cursor: pointer;
  color: var(--white);
  background: var(--primary-500-active);
  border: transparent;
  border-radius: var(--borderRadius);
  /* // letter-spacing: var(--letterSpacing); */
  padding: 8px 14px;
  /* box-shadow: var(--shadow-2); */
  transition: var(--transition);
  text-transform: capitalize;
  display: inline-block;
  font-weight: 800;
}

/* .btn:hover {
    background: var(--primary-700);
    box-shadow: var(--shadow-3);
}

.btn-hipster {
    color: var(--primary-500);
    background: var(--primary-200);
}

.btn-hipster:hover {
    color: var(--primary-200);
    background: var(--primary-700);
} */

.btn-block {
  width: 100%;
}

.btn-hero {
  font-size: 1.25rem;
  padding: 0.5rem 1.25rem;
}

.btn-danger {
  background: var(--red-light);
  color: var(--red-dark);
}

.btn-danger:hover {
  background: var(--red-dark);
  color: var(--white);
}


@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.loading {
  width: 6rem;
  height: 6rem;
  border: 5px solid var(--grey-400);
  border-radius: 50%;
  border-top-color: var(--primary-500);
  animation: spinner 2s linear infinite;
}

.loading-center {
  margin: 0 auto;
}

/* //////////////////////////////// */

.form-control:disabled {
  background: none !important;
}

.task-container {
  overflow-y: auto; /* Enable vertical scrolling */
  max-height: 72vh; /* Adjust height as needed */ 
}


::-webkit-scrollbar {
  width: 3px !important;
  height: 5px;
}

.file-list::-webkit-scrollbar {
  display: block !important;
}

.board::-webkit-scrollbar,
.tableresponse::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px var(--grey-300);
  border-radius: 0px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--grey-200);
  outline: 1px solid var(--grey-200);
  width: 1px;
  border-radius: 0px;
}

.react-datetime-picker__inputGroup__leadingZero{
  font-size: 12px;
}
 
::-webkit-scrollbar-thumb {
  background: rgb(126, 133, 132);
  border-radius: 0px;
}
 
::-webkit-scrollbar-thumb:hover {
  background: #8d9c9b;
} 

i {
  cursor: pointer;
  transition: all 0.2s;
}

.disvle {
  border: none;
  padding: 6px 0 !important;
  font-weight: 600;
}

p {
  /* text-align: justify !important; */
  word-break: break-word !important;
}

.word-break {
  text-align: justify !important;
  word-break: break-word !important;
}

@-webkit-keyframes leftRight {
  0% {
    -webkit-transform: translateX(0);
  }

  25% {
    -webkit-transform: translateX(-10px);
  }

  75% {
    -webkit-transform: translateX(10px);
  }

  100% {
    -webkit-transform: translateX(0);
  }
}

@keyframes leftRight {
  0% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(-10px);
  }

  75% {
    transform: translateX(10px);
  }

  100% {
    transform: translateX(0);
  }
}

h4 {
  font-weight: 500 !important;
}

.error-msg {
  position: absolute;
  color: red;
  font-size: 10px;
  margin-top: -18px;
}

.modal-backdrop.show {
  background: #000 !important;
  z-index: 1055 !important;
}

a {
  text-decoration: none !important;
}


.PhoneInputCountrySelect:focus+.PhoneInputCountryIcon--border {
  box-shadow: none !important;
  background: none;
  box-shadow: none;
}

table {
  width: 100%;
  /* box-shadow: var(--shadow-1); */
}

tr {
  background-color: #f9f9f9 !important;
  border-bottom: 1px solid var(--grey-100) !important;
}

tr th {
  padding: 8px;
  background-color: #edf5fd;
  color: var(--label-color);
  text-align: center;
  font-size: var(--font-12);
  font-weight: 500;
  text-transform: uppercase;
}

tr th:first-child {
  text-align: left;
}

tr td {
  padding: 8px 8px;
  font-size: var(--font-12);
  text-align: center;
  font-weight: 400;
}

tr td:first-child {
  text-align: left;
  font-size: 16.5;
  display: flex;
}

input[type="checkbox"]:focus {
  outline: 0px solid var(--primary-500-active) !important;
  border: 0px solid var(--primary-500-active) !important;
  border-radius: 5px;
}

input[type="checkbox"],
input[type="checkbox"]:checked {
  accent-color: var(--primary-500-active) !important;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.f-10 {
  font-size: 10px;
}

.f-11 {
  font-size: 11px;
}

.f-12 {
  font-size: var(--font-12);
}

.f-13 {
  font-size: var(--font-14);
}

.f-14 {
  font-size: var(--font-14);
}

.f-15 {
  font-size: var(--font-14);
}

.f-16 {
  font-size: var(--font-16);
}

.f-18 {
  font-size: var(--font-18);
}

.f-20 {
  font-size: var(--font-20);
}

.f-25 {
  font-size: var(--font-25);
}

.grey {
  color: grey;
  font-weight: 600;
}

.white {
  color: #fff !important;
  font-size: 15px !important;
}

.grey svg {
  margin-top: -2px;
  background-color: var(--primary-500-active);
  color: #fff;
  margin-right: 2px;
}

/*search start*/
.svgIcon {
  font-size: 15px !important;
  color: gray !important;
  margin-right: 5px;
}

.serch {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  height: 37px;
}

.serch input {
  height: 37px;
  margin-top: -9px;
  padding: 5px;
  border-radius: 0 0.25rem 0.25rem 0;
}

.serch .icon {
  display: flex;
}

.serch .icon span {
  height: 37px;
  width: 30px;
  padding: 5px;
  background: var(--label-bg);
  border: 1px solid var(--grey-200);
  border-right: hidden;
  border-radius: 0.25rem 0 0 0.25rem;
  margin-top: 3px;
}

.serch .icon svg {
  color: var(--grey-400);
  font-size: 20px;
}

.tableresponse {
   
  padding:0 32px;
}

.download-btn {
  text-align: right;
  /* margin: 20px 10px; */
}

.button {
  background: var(--primary-500);
  color: #fff;
  border-radius: 5px;
  padding: 3px 10px;
  margin-left: 20px;
  font-weight: 500 !important;
}
.jhk{
  padding:7px 3px !important;
}
svg {
  cursor: pointer;
}

.upload svg {
  display: none;
}

.upload:hover svg {
  display: block;
  background: linear-gradient(45deg, #118bc5, transparent);
  color: rgb(255, 255, 255);
  border-radius: 50%;
  font-size: 23px;
  align-items: center;
  padding: 4px;
  justify-content: center;

  margin-left: 40%;
}

.ql-picker-label {
  font-size: 12px !important;
}

.ql-snow.ql-toolbar button,
.ql-snow .ql-toolbar button {
  width: 20px !important;
}

.ql-snow.ql-toolbar button:hover,
.ql-snow .ql-toolbar button:hover,
.react-datetime-picker__button:hover {
  background-color: #fff !important;
  color: #222 !important;
  outline: none !important;
}
.ql-snow.ql-toolbar button:hover svg,
.ql-snow .ql-toolbar button:hover svg,
.react-datetime-picker__button:hover svg{
  
}
.ql-snow .ql-stroke{
  stroke: #444 !important;
}

.ql-snow.ql-toolbar button svg,
.ql-snow .ql-toolbar button svg,
.react-datetime-picker__button svg {
  
  outline: none !important;
}

a[data-tooltip-variant="info"] {
  z-index: 2000 !important;
}

.react-tooltip {
  color: #000 !important;
  background-color: #fff !important;
  box-shadow: var(--shadow-1) !important;
  z-index: 2000 !important;
  font-size: 12px !important;
  text-transform: none !important;
  /* width: 200px !important; */
}

/*search end*/

.categort {
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  box-shadow: var(--shadow-1);
  margin: 15px 0;
  border-radius: 2px;
}

.categort button,
.categort button:hover {
  color: inherit !important;
  background: none !important;
}

.collapsed {
  width: 80px;
  overflow: hidden;
  transition: width 0.3s ease;
}

.expanded {
  width: auto;
}

.activity tr:nth-child(even),
.rb-tabs-content-item tr:nth-child(even) {
  background-color: #f9f9f9 !important;
}

.activity tr,
.rb-tabs-content-item tr {
  background-color: #fff !important;
}

 
.btn,
button,
.btn-newlist {
  padding: 8px 15px !important;
  font-size: 20px;
}

.dropdown-item{
   padding: 4px 12px  !important;
  font-size:12px !important;
}

.btn-groupp .btn,
.btn-block,
.btnw {
  background: var(--primary-500-active) !important;
}

.btn-groupp .btn:hover,
.btn-block:hover,
.btnw:hover {
  background: var(--primary-500) !important;
  color: #fff !important;
}

.table>th {
  background-color: #edf5fd !important;
  padding: 8px !important;
}

.task-group {
  display: flex;
  gap: 4px;
}

.task-group .form-row {
  width: 100%;
  margin-bottom: 0;
}

.con-flex-block {
  display: flex;
}

.edit-buttons {
  margin: 5px 0px;
}

.created-by {
  float: right;
}

.board-scroll-button {
  position: absolute;
  right: 20px;
  gap: 10px;
  display: flex;
}

.board-scroll-button .left-button,
.board-scroll-button .right-button {
  background: var(--primary-500-active) !important;
  color: white;
  border-radius: 5px;
}

.board-scroll-button .left-button button,
.board-scroll-button .right-button button {
  color: white;
}

.btn-block {
  background: var(--primary-500-active);
  width: 100px;
  /* margin-top: 35px; */
  margin-bottom: 20px;
  color: var(--white);
  font-size: var(--font-14);
  font-weight: 600;
}

.btn-cancel {
  background: var(--primary-500-active);
  width: 100px;
  margin-top: 35px;
  margin-bottom: 20px;
  color: var(--white);
  margin-right: 20px;
  font-size: var(--font-14);
  font-weight: 600;
}

.work-logs {
  margin: 35px 0 10px 0px;
}

.work-logs .work-ul {
  min-width: 200px;
}

.all-projects {
   overflow-y: scroll;
  
  padding: 15px 0;
  padding-bottom: 40px;
}

.card-title {
  padding: 0px 0px 18px ;
  margin-bottom: 5px;
}

.page-button-section {
  padding-bottom: 0px;
}

.activity-table,
.activity-filter {
  padding: 15px 0;
}

.clints{
  padding: 8px 12px !important;
}
.rb-tabs-content{
  padding-bottom: 40px;
}
@media (min-width: 1400px) {
  .col-lg-4 {
    flex: 0 0 auto;
    width: calc(25% - 8px) !important;
  }
}

@media (max-width: 768px) {
  .side-image {
    display: none;
  }

  .rb-tabs-content {
    overflow: auto;
  }
}

@media (max-width: 420px) {
  .con-flex-block {
    display: block;
  }

  .created-by {
    float: none;
  }

  .btn-cancel,
  .btn-block {
    width: 80px;
  }

  .work-logs {
    margin: 0px 0 10px 0px;
  }
}