@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');
* {
font-family: 'Inter', sans-serif;
}

 
.wrapper {
  background: linear-gradient(122deg,
      rgba(68, 133, 128, 1) 28%,
      rgba(224, 237, 249, 1) 100%);
  padding: 0 20px 0 20px;
}

.main {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.side-image {
  /* //  background-image: url("https://img.freepik.com/premium-vector/secure-email-otp-authentication-verification-method_258153-468.jpg"); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  border-radius: 0px 10px 10px 0px;
}

/* img{
      width: 35px;
      position: absolute;
      top:30px;
      left:30px;
  } */

.signin .row {
  width: 900px;
  height: 550px;
  /* border-radius: 10px; */
  background: rgba(255, 255, 255, 0.6);
  box-shadow: 5px 5px 10px 1px rgba(0, 0, 0, 0.2);
}

.left {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

header {
  font-size: 1.2rem;
  color: #1577b3;
  font-weight: 900;
  margin-bottom: 40px;
  /* //  text-align: center; */
}

.signup {
  padding: 50px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 5px 5px 10px 1px rgba(0, 0, 0, 0.2);
  margin: 80px 50px;
  width: 70%;
}

.left-box {
  padding: 10px;
}

label {
  font-size: 12px;
  font-weight: 500;
  color: #374151;
}

input,
select, textarea {
  font-size: 12px !important;
}

input:focus,
textarea:focus,
select:focus {
  outline: 1px solid #1577b3 !important;
  border: 1px solid #1577b3 !important;
  border-radius: 5px;
}

input::selection,
textarea::selection,
select::selection {
  background-color: #1577b3;
  color: #fff;
}

textarea::placeholder,
input::placeholder,
section::placeholder {
  font-size: 12px !important;
}

.otp-box {
  display: flex;
}

.otp-box input {
  width: 35px;
  height: 35px;
  margin: 10px;
  text-align: center;
  display: flex;
}

.navbar {
  box-shadow: 5px 5px 10px 1px rgba(0, 0, 0, 0.2);
  padding: 5px 30px;
  z-index: 1;
  position: fixed;
  width: 100%;
  background-color: rgb(34, 89, 91);
}

.navbar-brand {
  color: #fff;
}

.all-clients .modal-content {
  width: auto !important;
}

.profile img {
  object-fit: cover;
  position: absolute;
  width: 300px;
  text-align: right;
  margin-left: 40px;
  cursor: pointer;
  border-radius: 50%;
}

.navbar i {
  font-size: 28px;
  padding: 0 25px;
  color: #fff;
  cursor: pointer;
}

.profile-header {
  margin: 0 10px;
}

.navbar button {
  color: #fff;
  border: 1px solid #fff;
  padding: 4px 30px;
}

.navbar button:hover {
  color: #fff;
  background-color: inherit;
  border: 1px solid #fff;
  padding: 4px 30px;
}

.navbar p {
  padding: 8px 0 1px 0;
}

.verify p {
  box-shadow: 5px 2px 10px 1px rgba(0, 0, 0, 0.2);
  padding: 30px 50px;
  background-color: #fff;
  text-align: center;
}

.verify i {
  font-size: 50px;
  color: #269585;
}

.signup a {
  text-decoration: none;
  font-size: 15px;
  color: #4ec1b1;
}

.navbar-toggler {
  display: none;
}

.navbar .form-flex {
  display: flex;
}

.bg-common-pg {
  background-image: url("https://wallpaperaccess.com/full/1900851.png");
  color: #fff;
  height: 100vh;
  background-repeat: none;
  background-repeat: no-repeat;
  background-size: cover;
}

.navbar-nav .nav-link {
  padding: 0 35px !important;
}

.add-btn {
  /* float: right; */
  width: auto;
  /* margin: 0 0 20px 0; */
background: var(--primary-500-active) !important;
  color: #fff !important;
  font-weight: 600 !important;
}
.add-btn svg{
  color:#fff !important;
}

 

.Toastify__toast {
  position: absolute;
  float: left;

}

.Toastify__toast {
  font-size: 12px;
  height: auto !important;
  min-height: auto !important;
}

.Toastify__toast-theme--dark {
  background-color: rgba(48, 125, 175, 0.9) !important;
  font-weight: 500 !important;
  color: #fff !important;
}

.Toastify__toast-theme--dark svg {
  fill: #fff !important;
}

.Toastify__toast-icon {
  width: 16px !important;
}

.Toastify__progress-bar--success {
  background: rgb(117, 201, 142) !important;
}

.Toastify__close-button {
  margin-top: 5px !important;
  margin-left: 10px !important;
}

.validateError {
  color: red;
  font-size: 11px;
  position: absolute;
  margin-top: -15px;
}

.validatePError {
  color: red;
  font-size: 11px;
  position: absolute;
}

.PhoneInput {
  display: flex;
  align-items: center;
  margin-top: 8px;
}

/*  */
.lowerCase {
  text-transform: lowercase;
}

.upperCase {
  text-transform: capitalize;
}

.Upper {
  text-transform: uppercase;
}

.textRight {
  text-align: right;
  margin-top: 30px;
}

.textLeft {
  text-align: left;
  margin-top: 30px;
}

.textCenter {
  text-align: center;
  margin-top: 30px;
}
 
.modal-content{
  padding:24px 15px !important;
}
 

.modal-title {
  /* text-align: center; */
  width: 100%;
  color: #444 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
    font-weight: 600 !important;
      font-size: 20px !important;
      padding-left: 15px;
}

.modal-header .btn-close{
  margin:0 !important
}
 .textC {
  text-transform: capitalize;
}

.modal-body .btn,
.modal-footer .btn {
  border-radius: 5px;
  background-color: var(--primary-500-active);
  font-size: 14px;
  font-weight: 500 !important;

  text-align: center;
  color: #fff;
  margin: 0 10px 0 0;
}

.modal-body .btn-container {
  display: flex;
  margin-top: 15px;
  justify-content: end;
}

.errordef {
  color: red;
  position: absolute;
  font-size: 12px;
  margin-top: -18px;
}

.testcase-link {
  color: #3498db; /* Link color */
  text-decoration: none; /* Remove underline */
  transition: color 0.3s ease; /* Smooth transition for hover effect */
}

.testcase-link:hover {
  color: #2980b9; /* Darker shade on hover */
  text-decoration: underline; /* Underline on hover */
  cursor: pointer;
}

@media (min-width: 992px) {

  .modal-lg,
  .modal-xl {
    --bs-modal-width: 1045px !important;
  }
}

.PhoneInput input {
  min-width: 0;
  width: 100%;
  padding: 9px 12px;
  border-radius: var(--borderRadius);
  background: var(--white);
  border: 1px solid var(--grey-200);
}

.PhoneInput .PhoneInputCountry {
  border: 1px solid grey;
  padding: 3px;
  border-radius: var(--borderRadius);
  background: var(--white);
  border: 1px solid var(--grey-200);
}

.modal-title {
  text-transform: capitalize;
}

.form-check-input:checked {
  background-color: var(--primary-500-active) !important;
  border-color: var(--primary-500-active) !important;
}

@media (max-width: 1200px) {
  .profile img {
    position: relative;
    width: 180px;
    margin-left: 0;
  }
}

@media (max-width: 800px) {
  .signup {
    flex-direction: column-reverse;
    padding: 25px 0;
    margin: 117px 30px;
    width: 100%;
  }

  .profile {
    display: flex;
    text-align: center;
    justify-content: center;
  }

  .profile img {
    position: relative;
    width: 150px;
    margin-left: 0;
  }

  .profile-header {
    font-size: 15px;
    margin: 0 10px;
  }

  label {
    font-size: 12px !important;
  }
  
  textarea::placeholder,
  input::placeholder,
  section::placeholder {
    font-size: 10px;
  }

  .form-control {
    font-size: 0.9rem;
  }

  .btn {
    padding: 3px 12px;
  }

  .modal-content {
    width: 80vw;
  }
}

@media (max-width: 500px) {
  .navbar-toggler {
    display: block;
    background: #fff;
    cursor: pointer;
  }

  .navbar .form-flex {
    display: none;
  }
}