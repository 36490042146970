.client .heading {
    padding-top: 65px;
}

.client .add-btn .btn {
    /* margin: 20px; */
    background: #fff;
    color: rgb(13, 14, 14);
    ;
    font-weight: 800;
}

.client .add-btn .btn:hover {
    /* margin: 20px; */
    background: #48669f;
    color: #fff;
    font-weight: 800;
}


.client .client-table {
    margin: 20px;
}

.bg-blur {
    top: 0;
    position: fixed;
    background: #ebf7f5;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0.6;
    right: 0;
    left: 0;
    z-index: 999;
}

.pg-blur {
    top: 0;
    position: fixed;
    background: #ebf7f5;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    right: 0;
    left: 0;
}

.modal-content,
.dropdown-menu {
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border: none;
}

button {
    border: none;
}

.dropdown-menu button {
    box-shadow: none !important;
    font-weight: 500 !important;
}

button {
    cursor: pointer;
}



.client button:hover,
.client button a:hover {
    background-color: #48669f !important;
    ;
    color: #fff;
    /* border: 1px solid rgb(13, 14, 14) !important;; */

}

.client .dropdown-menu button {
    border: none !important;
    ;
}

a {
    text-decoration: none;
    color: inherit !important;
}

a:hover {
    color: rgb(13, 14, 14);

}

.user-logo {
    font-size: 30px;
    cursor: pointer;
    margin-top: -12px;
    margin-left: 15px;
    color: #17469f;
}

.client .sidebar {
    height: auto;
    background: rgb(9, 31, 66);
}

.client th {
    padding: 15px 8px;
}

.client td {
    padding-left: 8px;
    padding-top: 8px;
    padding-bottom: 0px;
}

input .read-only,
input:focus .read-only,
.read-only {
    border: 0px !important;
    padding: 0 !important;
    font-weight: 900 !important;
    outline: none !important;
}



.client .table {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;

}

.client .table-reponsive {
    height: 100vh;
}

.client .nav-item {
    color: rgb(13, 14, 14);
    ;

    margin: 3px;
}


.client .nav .nav-item .nav-link {
    color: #fff !important;
    display: flex;
    justify-content: space-between;
}

.logo {
    width: 50px;
    height: 47px;
    border-radius: 50%;
}

.sidebar hr {
    color: #fff;
    border-width: 1.2px;
    opacity: 1;

}



.all-bg {
    background-color: #d8e3f4;

}

.table-responsive .dropdown p {
    font-size: 25px;
    cursor: pointer;
    margin-top: -12px;
}

table thead {
    background: #48669f;
}

#no-more-tables {
    border-radius: 5px;
}

.user-logo-dropdown.dropdown-menu {
    display: block;
    right: 22px;
    margin-top: -25px;
}

.dropdown-menu .list-group-item {
    border: none !important;
    padding: 4px 11px;
}

.dropdown-menu .list-group-item a:hover {
    color: #48669f;
    ;
    font-weight: bold;
}

.popup-box {
    display: block;
    position: fixed;
    z-index: 8;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}