.dark {
  background: #172c36 !important;
  color: #e5e0e0 !important;
}

.dark .modal-body,
.dark article {
  background: #172c36 !important;
}
.dark .card-title,
.dark p,
.dark h5 {
  color: #e5e0e0 !important;
}

.dark .info p,
.dark .info h5,
.dark .dropdown-item,
.dark label,
.dark .form-label {
  color: #e5e0e0 !important;
}
.dark .box p {
  background: inherit !important;
  color: #172c36 !important;
}
.dark .dropdown-menu,
.dark h2,
.dark p,
.dark h6,
.dark .options,
.dark .permission,
.dark .search-by-role,
.dark td,
.dark .proj,
.dark .color,
.dark .total-time,
.dark .show-dropdown,
.dark .dropdown-btn,
.dark   .single-question,
.dark .answer, 
.dark .css-1nmdiq5-menu,
.dark .project-name
{

  background: #172c36 !important;
  color: #e5e0e0 !important;
}

.dark .toggle-btn{ 
  color: #172c36 !important;
}
.dark .edit, .dark .nav-logo p span{
  color:#e5e0e0 !important;
}
.dark .leftBox,
.dark .prdf{
    background: #172c36 !important;
      color: #e5e0e0 !important;
      border: 1px solid;
}

.dark button{
    background: var(--primary-500) !important;
        color: #e5e0e0 !important;
}

.dark .rb-tabs-item.active {
    color: #acd7f3 !important;
}
.dark .rb-tabs-header .rb-tabs-active-item {
    background: var(--primary-500-active) !important;
}

.dark   select[name="project"]{
    background: #172c36 !important;
        color: #e5e0e0 !important;
}
.dark tr:nth-child(2n),
.dark tr {
  background: #172c36 !important;
  color: #e5e0e0 !important;
}

.dark .box {
  background: #24353c !important;
}

.dark .avatar-image span,
.dark .dropdown-item:hover {
  color: #172c36 !important;
}

.dark .tasktitle {
  color: #172c36 !important;
}

.dark .task-heading svg {
  background: none !important;
}

.dark .crumb a {
  background: #464e54 !important;
}


.dark .css-1nmdiq5-menu:hover{
  color: #172c36 !important;
    background: #fffafa !important;
}